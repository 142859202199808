import { getTrans } from '../helpers/getTrans';

export enum HTTP_STATUS {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    MULTIPLE_CHOICES = 300,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    GONE = 410,
    UNPROCESSABLE_ENTITY = 422,
    LOCKED = 423,
    TOO_MANY_REQUESTS = 429,
    INTERNAL_SERVER_ERROR = 500,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
}

export interface HttpStatusDefaultErrorMessages {
    [HTTP_STATUS.OK]: string;
    [HTTP_STATUS.CREATED]: string;
    [HTTP_STATUS.NO_CONTENT]: string;
    [HTTP_STATUS.MULTIPLE_CHOICES]: string;
    [HTTP_STATUS.BAD_REQUEST]: string;
    [HTTP_STATUS.UNAUTHORIZED]: string;
    [HTTP_STATUS.FORBIDDEN]: string;
    [HTTP_STATUS.NOT_FOUND]: string;
    [HTTP_STATUS.CONFLICT]: string;
    [HTTP_STATUS.GONE]: string;
    [HTTP_STATUS.LOCKED]: string;
    [HTTP_STATUS.TOO_MANY_REQUESTS]: string;
    [HTTP_STATUS.UNPROCESSABLE_ENTITY]: string;
    [HTTP_STATUS.INTERNAL_SERVER_ERROR]: string;
    [HTTP_STATUS.BAD_GATEWAY]: string;
    [HTTP_STATUS.SERVICE_UNAVAILABLE]: string;
    [HTTP_STATUS.GATEWAY_TIMEOUT]: string;
}

export const httpStatusDefaultErrorMessages: HttpStatusDefaultErrorMessages = {
    [HTTP_STATUS.OK]: 'Operation successful.',
    [HTTP_STATUS.CREATED]: 'Resource created successfully.',
    [HTTP_STATUS.NO_CONTENT]: 'No content available.',
    [HTTP_STATUS.MULTIPLE_CHOICES]: 'Multiple choices available.',
    [HTTP_STATUS.BAD_REQUEST]: 'Bad request.',
    [HTTP_STATUS.UNAUTHORIZED]: 'Unauthorized: You do not have permission to access this resource. Please login',
    [HTTP_STATUS.FORBIDDEN]: 'Forbidden: You do not have permission to access this resource.',
    [HTTP_STATUS.NOT_FOUND]: 'Resource not found.',
    [HTTP_STATUS.CONFLICT]: 'Conflict with existing resource.',
    [HTTP_STATUS.GONE]: 'Resource gone.',
    [HTTP_STATUS.LOCKED]: 'Resource locked.',
    [HTTP_STATUS.TOO_MANY_REQUESTS]: getTrans('api-response-error.tooManyActionCompleteAttemptsResponsableException', 'Hold your horses, cowboy - too many attempts!'),
    [HTTP_STATUS.UNPROCESSABLE_ENTITY]: 'Unprocessable entity.',
    [HTTP_STATUS.INTERNAL_SERVER_ERROR]: 'Internal server error.',
    [HTTP_STATUS.BAD_GATEWAY]: 'Bad gateway.',
    [HTTP_STATUS.SERVICE_UNAVAILABLE]: 'Service unavailable.',
    [HTTP_STATUS.GATEWAY_TIMEOUT]: getTrans('validation.gatewayTimeout', 'The request timed out. Please try again later.'),
};
